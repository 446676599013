export default {
  // 路由路径
  path: "/app-cancellation-agreement",
  // 动态导入Promise组件
  component: () => import("./app-cancellation-agreement"),
  // 路由元信息
  meta: {
    title: "熊猫点钢app注销协议",
    authenticated: false
  }
};

import axios from "axios";
import $qs from "qs";
import router from "@/router";
import store from "@/store";
//使用axios下面的create([config])方法创建axios实例，其中config参数为axios最基本的配置信息。
const API = axios.create({
  baseUrl: process.env.VUE_APP_RESOURCE_BASE_URL, //请求后端数据的基本地址，自定义
  timeout: 60000, //请求超时设置，单位ms
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  // 序列化请求正文
  transformRequest: (data) => $qs.stringify(data),
  // 序列化请求参数
  paramsSerializer: (params) => $qs.stringify(params),
});
// 添加请求拦截器
API.interceptors.request.use(
  function (config) {
    if (!config.headers.Authorization) {
      config.headers.Authorization = store.getters.getAuthorization;
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    console.log(error);
    return Promise.reject(error);
  }
);

// 添加响应拦截器
API.interceptors.response.use(
  function (response) {
    // const dataAxios = response.data
    return response;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    // 需要认证但未认证
    if (router.currentRoute.value.meta.mobile) {
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
      // 清除认证
      store.commit("clearAuthorization");
      // // 路由替换
      if (router.currentRoute.value.fullPath === "/rechargeCenter") {
        store.dispatch("setLogin", true);
      }
    }
    // 不需要认证或已认证但无权限
    else if (error.response && error.response.status === 403) {
      // 路由替换
      router.replace("/");
    }
    return Promise.reject(error);
  }
);
//导出我们建立的axios实例模块，ES6 export用法
export default API;

// 存放一些全局的组件
import IconSvg from "./IconSvg";
import {
  ElContainer,
  ElHeader,
  ElMain,
  ElScrollbar,
  ElCarousel,
  ElCarouselItem,
  ElRow,
  ElCol,
  ElCard,
  ElMenu,
  ElMenuItem,
  ElInput,
  ElButton,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElTimeline,
  ElTimelineItem,
  ElPagination,
  ElImage,
  ElForm,
  ElFormItem,
  ElSelect,
  ElOption,
  ElDialog,
  ElTabs,
  ElTabPane,
  ElTable,
  ElTableColumn,
  ElCollapse,
  ElCollapseItem,
  ElConfigProvider,
  ElIcon,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
} from "element-plus/lib/components";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
//全局注册icon-svg
export default {
  install(app) {
    Object.keys(ElementPlusIconsVue).forEach((key) => {
      app.component(key, ElementPlusIconsVue[key]);
    });
    app.component(IconSvg.name, IconSvg);
    app.component("ElContainer", ElContainer);
    app.component("ElHeader", ElHeader);
    app.component("ElMain", ElMain);
    app.component("ElScrollbar", ElScrollbar);
    app.component("ElCarousel", ElCarousel);
    app.component("ElCarouselItem", ElCarouselItem);
    app.component("ElRow", ElRow);
    app.component("ElCol", ElCol);
    app.component("ElCard", ElCard);
    app.component("ElMenu", ElMenu);
    app.component("ElMenuItem", ElMenuItem);
    app.component("ElInput", ElInput);
    app.component("ElButton", ElButton);
    app.component("ElBreadcrumb", ElBreadcrumb);
    app.component("ElBreadcrumbItem", ElBreadcrumbItem);
    app.component("ElTimeline", ElTimeline);
    app.component("ElTimelineItem", ElTimelineItem);
    app.component("ElPagination", ElPagination);
    app.component("ElImage", ElImage);
    app.component("ElForm", ElForm);
    app.component("ElFormItem", ElFormItem);
    app.component("ElOption", ElOption);
    app.component("ElSelect", ElSelect);
    app.component("ElDialog", ElDialog);
    app.component("ElTabs", ElTabs);
    app.component("ElTabPane", ElTabPane);
    app.component("ElTable", ElTable);
    app.component("ElTableColumn", ElTableColumn);
    app.component("ElCollapse", ElCollapse);
    app.component("ElCollapseItem", ElCollapseItem);
    app.component("ElConfigProvider", ElConfigProvider);
    app.component("ElIcon", ElIcon);
    app.component("ElDropdown", ElDropdown);
    app.component("ElDropdownMenu", ElDropdownMenu);
    app.component("ElDropdownItem", ElDropdownItem);
  },
};

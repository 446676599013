import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "vuex";
import agreementRouter from "../views/agreement/route";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
const breakpoints = useBreakpoints(breakpointsTailwind);
let homePage = breakpoints.smaller("md").value ? "home_mobile" : "home";
console.log(breakpoints.smaller("md"), homePage);
const routes = [
  {
    path: "/",
    name: "/",
    redirect: homePage,
    component: () => import("@/main/main.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        // 动态导入Promise组件
        component: () => import("@/views/home/home3"),
        // 路由元信息
        meta: {
          title: "首页",
          authenticated: false,
          activeMenu: "/home",
        },
      },
      {
        path: "/home_mobile",
        name: "home_mobile",
        // 动态导入Promise组件
        component: () => import("@/views/home/home_mobile"),
        // 路由元信息
        meta: {
          title: "首页",
          authenticated: false,
          activeMenu: "/home_mobile",
        },
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: () => import("@/views/aboutUs/aboutUs"),
        meta: {
          title: "关于我们",
          authenticated: false,
          activeMenu: "/aboutUs",
          mobile: false,
        },
      },
      {
        path: "/aboutUs_mobile",
        name: "aboutUs_mobile",
        component: () => import("@/views/aboutUs/aboutUs_mobile"),
        meta: {
          title: "关于我们",
          authenticated: false,
          activeMenu: "/aboutUs_mobile",
          mobile: true,
        },
      },
      {
        path: "/dealMethod",
        name: "行业解决方案",
        component: () => import("@/views/dealMethod/dealMethod"),
        meta: {
          title: "行业解决方案",
          authenticated: false,
          activeMenu: "/dealMethod",
        },
      },
      {
        path: "/newsCenter",
        name: "newsCenter",
        component: () => import("@/views/newsCenter/newsCenter"),
        meta: {
          title: "新闻中心",
          authenticated: false,
          activeMenu: "/newsCenter",
        },
      },
      {
        path: "/newsCenter_mobile",
        name: "newsCenter_mobile",
        component: () => import("@/views/newsCenter/newsCenter_mobile"),
        meta: {
          title: "新闻中心",
          authenticated: false,
          activeMenu: "/newsCenter_mobile",
        },
      },
      {
        path: "/contactUs",
        name: "contactUs",
        component: () => import("@/views/contactUs/contactUs"),
        meta: {
          title: "联系我们",
          authenticated: false,
          activeMenu: "/contactUs",
        },
      },
      {
        path: "/contactUs_mobile",
        name: "contactUs_mobile",
        component: () => import("@/views/contactUs/contactUs_mobile"),
        meta: {
          title: "联系我们",
          authenticated: false,
          activeMenu: "/contactUs_mobile",
        },
      },
      {
        // 路由路径
        path: "/help",
        // 动态导入Promise组件
        component: () => import("@/views/help/help"),
        // 路由元信息
        meta: {
          title: "APP帮助中心",
          authenticated: false,
          mobile: true,
        },
      },
      {
        // 路由路径
        path: "/pointedPrice",
        // 动态导入Promise组件
        component: () => import("@/views/activities/pointedPrice_ac"),
        // 路由元信息
        meta: {
          title: "点价活动",
          authenticated: false,
          mobile: true,
          type: "supply",
        },
      },
      {
        // 路由路径
        path: "/rankingList",
        // 动态导入Promise组件
        component: () => import("@/views/activities/rankingList"),
      },
      {
        // 路由路径
        path: "/about",
        // 动态导入Promise组件
        component: () => import("@/views/about/about"),
        // 路由元信息
        meta: {
          title: "交易规则",
          authenticated: false,
          mobile: true,
          type: "supply",
        },
      },

      //协议路由
      ...agreementRouter,
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  // linkActiveClass: "actived",
  routes,
});
// 定义路由器全局前置守卫
router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next({
      path: homePage,
      replace: true,
    });
  }
  const auth = sessionStorage.getItem("Authorization");
  // 需要认证但未认证
  if (to.meta.authenticated && !auth) {
    if (to.fullPath === "/") {
      next({
        path: homePage,
        replace: true,
      });
    } else {
      next({
        path: homePage,
        query: { redirect: to.fullPath },
        replace: true,
      });
    }
  }
  // 不需要认证或已认证
  else {
    if (to.fullPath === homePage && auth) {
      next();
    } else {
      if (to.meta.title) {
        window.document.title = to.meta.title;
      }
      next();
    }
  }
  // const mobilePath = [
  //   "/trading-rules",
  //   "/about",
  //   "/instant-xmdg-agreement",
  //   "/instant-sale-agreement",
  //   "/authorization-agreement",
  //   "/notice-sale-agreement",
  //   "/registration-agreement",
  //   "/declare-agreement",
  //   "/after-sale-agreement",
  //   "/delayed-sale-agreement",
  //   "/purchase-sale-contract",
  //   "/app-cancellation-agreement",
  // ];
  // if (mobilePath.findIndex((v) => v == to.fullPath) >= 0 || to.meta.mobile) {
  //   store.commit("setMobile", true);
  // } else {
  //   store.commit("setMobile", false);
  // }
});

export default router;

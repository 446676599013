export default {
  // 路由路径
  path: "/purchase-sale-contract",
  // 动态导入Promise组件
  component: () => import("./purchase-sale-contract"),
  // 路由元信息
  meta: {
    title: "授权协议",
    authenticated: false
  }
};

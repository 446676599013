export default {
  // 路由路径
  path: "/after-sale-agreement",
  // 动态导入Promise组件
  component: () => import("./agreement"),
  // 路由元信息
  meta: {
    title: "售后服务",
    authenticated: false
  }
};

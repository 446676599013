export default {
  // 路由路径
  path: "/authorization-agreement",
  // 动态导入Promise组件
  component: () => import("./authorization-agreement"),
  // 路由元信息
  meta: {
    title: "授权协议",
    authenticated: false
  }
};

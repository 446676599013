export default {
  // 路由路径
  path: "/trading-rules",
  // 动态导入Promise组件
  component: () => import("./trading-rules"),
  // 路由元信息
  meta: {
    title: "交易规则",
    authenticated: false
  }
};

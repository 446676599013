export default {
  // 路由路径
  path: "/declare-agreement",
  // 动态导入Promise组件
  component: () => import("./declare-agreement"),
  // 路由元信息
  meta: {
    title: "法律声明",
    authenticated: false
  }
};

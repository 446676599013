import registrationAgreement from "./registration-agreement/route";
import authorizationAgreement from "./authorization-agreement/route";
import tradingRules from "./trading-rules/route";
import contractRules from "./purchase-sale-contract/route";
import delayedSaleRouter from "./delayed-sale-agreement/route";
import instantSaleRouter from "./instant-sale-agreement/route";
import instantXMDGRouter from "./instant-xmdg-agreement/route";
import notoceSaleRouter from "./notice-sale-agreement/route";
import declareRouter from "./declare-agreement/route";
import afterSaleRouter from "./after-sale-agreement/route";
import userRouter from "./user-privacy-agreement/route";
import cancellationAgreement from "./app-cancellation-agreement/route";

export default [
  //注册协议
  registrationAgreement,
  //授权协议
  authorizationAgreement,
  //交易规则
  tradingRules,
  //钢材购销合同
  contractRules,
  //延时拿货销售合同
  delayedSaleRouter,
  //即时拿货销售合同
  instantSaleRouter,
  //通知拿货销售合同
  notoceSaleRouter,
  //法律声明
  declareRouter,
  //售后服务
  afterSaleRouter,
  //用户隐私协议
  ...userRouter,
  instantXMDGRouter,
  //注销协议
  cancellationAgreement,
];

<template>
  <svg class="svg-icon" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>
<script>
import "@/assets/iconfont/iconfont.js";
import { computed, toRefs } from 'vue'
export default {
  name: 'IconSvg',
  props: {
    iconClass: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const p = toRefs(props)
    const iconName = computed(() => {
      return '#' + p.iconClass.value
    })
    return {
      iconName
    }
  },
}
</script>
<style>
.svg-icon {
  width: 1em;
  height: 1em;
  font-size: 18px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>

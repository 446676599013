export default {
  // 路由路径
  path: "/registration-agreement",
  // 动态导入Promise组件
  component: () => import("./registration-agreement"),
  // 路由元信息
  meta: {
    title: "注册协议",
    authenticated: false
  }
};

export default {
  // 路由路径
  path: "/instant-sale-agreement",
  // 动态导入Promise组件
  component: () => import("./sale-agreement"),
  // 路由元信息
  meta: {
    title: "钢材购销合同",
    authenticated: false
  }
};

import { createApp } from "vue";
import App from "./App.vue";
// import 'amfe-flexible';
import store from "./store";

import Animate from "animate.css"; //字体动画
import "@/assets/css/base.css";

import GlobalComponents from "@/components/GlobalComponents"; // 全局组件

import lazyPlugin from "vue3-lazy";

import axios from "@/plugins/axios.js";

//移动端适配
import "@/libs/rem.js";
// import "lib-flexible/flexible.js";
// 导入index.css
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/display.css";

const app = createApp(App);
app.provide("$store", store);

import router from "./router";
app.provide("$router", router);
app.provide("$axios", axios);

app
  .use(store)
  .use(router)
  .use(lazyPlugin, {
    // loading: require('img/default.jpg'), // 加载时默认图片
  })
  .use(Animate)
  .use(GlobalComponents);
app.mount("#app");

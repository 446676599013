<template>
  <el-config-provider>
    <router-view />
  </el-config-provider>
</template>
<script setup name="APP"></script>
<style lang="less">
* {
  box-sizing: border-box;
}
html {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
body {
  margin: 0;
  width: 100%;
  min-width: 390px;
  min-height: 100vh;
  line-height: 1.4;
  font-size: var(--el-font-size-base);
  font-weight: 400;
  color: var(--text-color);
  background-color: var(--bg-color);
  direction: ltr;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color var(--el-transition-duration-fast);
  overflow: overlay;
}

.iconpark-icon {
  width: 1em;
  height: 1em;
}
.common-layout {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  .el-header {
    position: relative;
    background-color: #394558;
    padding: 0;
  }
  .el-main {
    padding: 0;
    width: 100%;
    overflow: hidden;
  }
}
.imgInTitle {
  width: 100%;
  height: 100%;
}
.textTitle {
  font-size: 36px;
  padding: 20px;
}
.textContent {
  padding: 20px;
  font-size: 16px;
  line-height: 32px;
}
.titleInCard {
  // color: #2165b0;
  font-size: 20px;
  // line-height: 90rem;
  font-weight: bold;
}
.contentInCard {
  display: block;
  font-size: 15px;
  // line-height: 60rem;
}
.colBtn {
  width: 150px;
  // background-color: rgb(230 237 246 / 0%);
  --el-button-bg-color: rgb(0 70 17 / 0%) !important;
  // --el-button-hover-text-color: #004fa1 !important;
  --el-button-border-color: #004fa1 !important;
  --el-button-active-bg-color: #004fa1 !important;
  // --el-button-active-text-color: #004fa1 !important;
}
.centerContent {
  font-size: 80px;
  position: relative;
  color: #fff;
  height: 100%;
  top: -100%;
  line-height: 50vh;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar {
  display: none;
}
</style>

export default [
  {
    // 路由路径
    path: "/user-privacy-agreement",
    // 动态导入Promise组件
    component: () => import("./user-privacy-agreement"),
    // 路由元信息
    meta: {
      title: "用户隐私协议",
      authenticated: false,
      mobile: true,
    },
  },
  {
    // 路由路径
    path: "/user-privacy-agreement-20231205",
    // 动态导入Promise组件
    component: () => import("./user-privacy-agreement-20231205"),
    // 路由元信息
    meta: {
      title: "用户隐私协议",
      authenticated: false,
      mobile: true,
    },
  },
];
